<template>
  <ListRow>
    <td>
      <span :class="['badge_state', badgeType]">{{
        rowData.quotationStatus == "T" ? rowData.quotationStatusName : rowData.statusName
      }}</span>
    </td>
    <td>{{ rowData.upEstimateNum ? "재견적" : "" }}</td>
    <td>{{ rowData.reqDate ? rowData.reqDate : rowData.regDate | dateStringFormat }}</td>
    <td>{{ rowData.estimateNum }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.title">
      <span v-if="!rowData.quotationNum">
        <router-link
          :to="toPath"
          :class="'link_subject'"
          :data-ellip="rowData.title"
          v-html="rowData.title"
      /></span>
      <span
        v-else
        v-click-outside="onClickDialougeClose"
        style="cursor: pointer; text-decoration: underline"
        @click="onClickPopEstimatePrint(rowData)"
        >{{ rowData.title }}</span
      >
    </td>
    <td class="td_ellip" :data-ellip="rowData.purchaseManagerName">
      {{ rowData.quotationNum ? "" : rowData.purchaseManagerName }}
    </td>
  </ListRow>
</template>
<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

import ApiService from "@/services/ApiService";

export default {
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  data() {
    return {
      isToggle: false,
      estimateList: Object,
    };
  },
  computed: {
    badgeType() {
      //       0: {codeId: "0", codeName: "전체"}
      // 1: {codeId: "T", codeName: "임시저장"}
      // 2: {codeId: "R", codeName: "견적요청"}
      // 3: {codeId: "S", codeName: "견적회신"}
      switch (this.rowData.statusCode) {
        case "T":
          return "";
        case "S":
          return "badge_purple";
        case "D":
          return "badge_blue";
      }
      return "";
    },
    toPath() {
      // 임시 저장 상태이면 수정페이지로, 완료 상태면 상세페이지로.
      // 구매사/공급사 구분이 필요하다면 처리.
      const { status, estimateNum } = this.rowData;

      // 임시 저장일 때만 수정 가능.
      const canModify = status === "T";
      let path = "";
      if (status === "T") {
        //임시저장
        path = this.$routerPath.ESTIMATE_MODIFY;
      } else if (status === "D") {
        //발송완료
        path = this.$routerPath.ESTIMATE_VIEW;
      } else {
        //견적 작성
        path = this.$routerPath.ESTIMATE_WRITE;
      }

      const ret = makePathWithQuery(path, estimateNum, this.$route.query);

      return ret;
    },
  },
  mounted() {},
  methods: {
    onClickDialougeOpen(e) {
      this.estimateGetData();
      this.isToggle = true;
    },
    onClickDialougeClose() {
      if (!this.isToggle) {
        return;
      }
      this.isToggle = false;
    },
    async estimateGetData() {
      const path = `${this.$apiPath.QUOTATION}?type=list&estimateNum=${this.rowData.estimateNum}&startDate=20210101&endDate=20221231`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.estimateList = data;
    },
    onClickPopEstimatePrint(item) {
      // 정보에 구매사담당자 포함하겠다.
      // item.prchsrMngrNm = this.rowData.prchsrMngrNm;

      this.$emit("onClickPopEstimatePrint", item);
    },
  },
};
</script>
