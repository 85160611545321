<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">견적현황</h2>
      </div>
      <div class="body_section">
        <Tabs
          :keyParam="KEY_PARAM"
          :dataList="TAB_DATA_LIST"
          :currentValue.sync="currentTabValue"
          @onChangeTabValue="onChangeTabValue"
        />
        <EstimateListPresent v-if="currentTabValue === 'present'" />
        <EstimateListQuotation v-else-if="currentTabValue === 'quotation'" />
      </div>
    </div>

    <template v-slot:popup>
      <!-- <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      /> -->
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Tabs from "@/components/common/tabs/Tabs";
import EstimateListPresent from "@/components/user/estimate/list/EstimateListPresent";

import EstimateListQuotation from "@/components/user/estimate/list/EstimateListQuotation";
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";
import EstimateListFilterBar from "@/components/admin/estimate/list/EstimateListFilterBar";

import EstimateListLine from "@/components/user/estimate/list/EstimateListLine";

import PageMixin from "@/mixins/PageMixin";
import EstimateListMixin from "@/mixins/estimate/EstimateListMixin";

import { mapState } from "vuex";
import { ESTIMATE_LIST_ACTION } from "@/store";
import excelKey from "@/constants/excelKey";
import { ONE_WEEK, YESTERDAY, ALL } from "@/constants/dates";

export default {
  components: {
    PageWithLayout,
    Tabs,
    ListPage,
    TableHead,
    SelectBoxPageRange,
    ExcelDonwloadPopup,
    EstimateListFilterBar,
    EstimateListLine,
    EstimateListPresent,
    EstimateListQuotation,
  },
  data() {
    return {
      KEY_PARAM: "tab",
      TAB_DATA_LIST: [
        { value: "present", text: "수신 견적" },
        { value: "quotation", text: "발송 견적" },
      ],
      currentTabValue: "present",
      selectBoxPrev: "", // alert 띄우고 취소하면 이전 값으로 돌아가야 함.
      rowDataSelected: null,
      isPopChangeStatus: false,
      isPopReview: false,
    };
  },
  computed: {},
  beforeMount() {
    // const params = this.checkUrlQuery();
    // this.$store.dispatch(ESTIMATE_LIST_ACTION).then(() => {
    //   this.getDataInit(params);
    // });
    // this.excelKey = excelKey.ESTIMATE_PRUCHASE;
  },
  methods: {
    onChangeTabValue(value) {
      let query = { page: 1, tab: value };
      this.$router.replace({ query: query });
    },
  },
  onChangePartnerStatus(to, from, rowData) {
    this.selectBoxPrev = from;
    this.rowDataSelected = rowData;
    if (this.rowDataSelected.status === "N") {
      // 검토
      this.isPopReview = true;
    } else {
      this.isPopChangeStatus = true;
    }
  },
};
</script>
