import { mapState } from "vuex";
// import { ANNOUNCE_LIST_ACTION } from '@/store/modules/announce/action';
import ListPageMixin from "@/mixins/ListPageMixin";
import { stringToDate } from "@/utils/dateUtils";

const EstimateListMixin = {
  mixins: [ListPageMixin],
  data() {
    return {};
  },
  beforeMount() {
    // const params = this.checkUrlQuery();
    // this.getDataInit(params);
  },
  computed: {
    // ...mapState({
    //   statusList: (state) => state.announce.statusList,
    // }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objDates = this.checkQueryDates(query, "startDate", "endDate");
      const objType = { type: "list" };
      // const objStatus = { status: query.status };
      const objStatus = this.checkQueryStatus(query);
      return Object.assign({}, objKeyword, objDates, objPaging, objType, objStatus); //objPaging,
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }

      const cateNum = null; //category2 || category1 || category0;

      if (cateNum) {
        params.cateNum = cateNum;
      }

      return params;
    },
    checkQueryDates(query, startDtKey, endDtKey) {
      const startDt = query[startDtKey];
      const endDt = query[endDtKey];

      const isValid = startDt && endDt;
      let ret = {};

      if (isValid) {
        this.defaultDates = [stringToDate(startDt), stringToDate(endDt)];
        ret[startDtKey] = startDt;
        ret[endDtKey] = endDt;
      } else {
        this.defaultDates = [];
      }
      return ret;
    },
    checkQueryStatus(query) {
      const status = query.status || "0";
      let ret = {};
      ret.status = status;

      this.defaultStatusId = status;

      return ret;

      // {
      //   status: query.status;
      // }
    },
  },
};
export default EstimateListMixin;
