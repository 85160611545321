<template>
  <FilterBarBase
    :excelBtn="false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl class="w152">
      <dt>구분</dt>
      <dd>
        <SelectBox :dataList="statusList" :isDisabled="isDisabledStatus" :value.sync="statusId" />
      </dd>
    </dl>
    <dl>
      <dt>견적기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>

      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w208" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input class="w425" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";

import { dateToString } from "@/utils/dateUtils";

export default {
  name: "EstimateListFilterBar",
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
    CheckboxOnlyOne,
  },
  mixins: [FilterBarMixin],
  props: {
    defaultStatusId: String,
    defaultKeywordInputText: String,

    statusList: Array,
    //defaultDateId: String,
  },
  data() {
    return {
      statusId: this.defaultStatusId,
      keywordSelectedId: this.defaultKeywordSelectedId,
      keywordInputText: this.defaultKeywordInputText,
    };
  },
  methods: {
    getCommonFilterbarData() {
      const isValidDates = this.dates && this.dates.length === 2;

      const ret = {
        status: this.statusId,
        keywordSelectedId: this.keywordSelectedId,
        keywordInputText: this.keywordInputText.trim(),
        startDate: isValidDates ? dateToString(this.dates[0]) : null,
        endDate: isValidDates ? dateToString(this.dates[1]) : null,
      };

      return ret;
    },
    onClickSearch() {
      let obj = this.getCommonFilterbarData();
      obj.isMyWork = this.isMyWork;

      this.$emit("onClickSearch", obj);
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit("onClickExcelBtn", isDatesSelected);
    },
    onClickReset() {
      this.onClickResetFilterBar();
      this.keywordSelectedId = "title";
      this.statusId = "0";
    },
  },
};
</script>
<style lang="scss" scoped>
.box_filter dl + dl {
  padding-left: 188px;
  & dt {
    width: 124px;
  }
}
</style>
