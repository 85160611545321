<template>
  <div>
    <EstimateListFilterBar
      ref="filterBar"
      :statusList="statusList"
      :defaultStatusId="defaultStatusId"
      :keywordDatas="keywordList"
      :defaultKeywordSelectedId="defaultKeywordSelectedId"
      :defaultKeywordInputText="defaultKeywordInputText"
      :defaultDateId="defaultDateId"
      :defaultDates="defaultDates"
      @onClickSearch="onClickSearch"
    />

    <TableHead title="견적요청받은목록">
      <template v-slot:headRight>
        <SelectBoxPageRange
          :currentMaxRowCount.sync="size"
          @update:currentMaxRowCount="onChangeRowCount"
        />
      </template>
    </TableHead>
    <ListPage
      :list="dataList"
      :currentPageIndex="currentPageIndex"
      :totalPageCount="totalPageCount"
      :maxRowCount="size"
      :maxPaginationCount="maxPaginationCount"
      @onChangePage="onChangePage"
    >
      <template v-slot:colgroup>
        <col style="width: 130px" />
        <!-- 상태 -->
        <col style="width: 80px" />
        <!-- 견적 -->
        <col style="width: 110px" />
        <!-- 견적요청일자 -->
        <col style="width: 210px" />
        <!-- 견적요청번호 -->
        <col style="width: 425px" />
        <!-- 견적요청명 -->
        <col style="width: 135px" />
        <!-- 구매사담당자 -->
      </template>
      <template v-slot:tr>
        <th>상태</th>
        <th>견적</th>
        <th>견적요청일자</th>
        <th>견적요청번호</th>
        <th>견적요청명</th>
        <th>구매사담당자</th>
      </template>
      <template v-slot:row="slotProps">
        <EstimateListLine :rowData="slotProps.rowData" />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="6" class="td_empty">견적이 없습니다.</td>
        </tr>
      </template>
    </ListPage>
  </div>
</template>
<script>
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";
import EstimateListFilterBar from "@/components/admin/estimate/list/EstimateListFilterBar";

import EstimateListLine from "@/components/user/estimate/list/EstimateListLine";

import PageMixin from "@/mixins/PageMixin";
import EstimateListMixin from "@/mixins/estimate/EstimateListMixin";

import { mapState } from "vuex";
import { ESTIMATE_LIST_ACTION } from "@/store";
import ApiService from "@/services/ApiService";
import excelKey from "@/constants/excelKey";
import { ONE_WEEK, YESTERDAY, ALL } from "@/constants/dates";

export default {
  name: "EstimateListPresent",
  components: {
    ListPage,
    TableHead,
    SelectBoxPageRange,
    ExcelDonwloadPopup,
    EstimateListFilterBar,
    EstimateListLine,
  },
  mixins: [PageMixin, EstimateListMixin],
  data() {
    return {
      isToggle: false,
      estimateList: Object,
      API_PATH: this.$apiPath.ESTIMATE,
      keywordList: [
        { id: "title", code: "title", desc: "견적요청명" },
        { id: "purchaseManagerName", code: "purchaseManagerName", desc: "구매사담당자" },
        { id: "reqName", code: "reqName", desc: "견적요청작성자" },
      ],

      defaultStatusId: "0",
      defaultKeywordSelectedId: "title",
      defaultDates: [this.moment().day(-6), this.moment()],

      isPopEstimatePrint: false,
      estimatePrint: null,
      estimateStatusList: [],
    };
  },
  computed: {
    statusList() {
      return this.estimateStatusList;
    },
  },
  created() {
    this.getStatus();
  },
  beforeMount() {
    const params = this.checkUrlQuery();
    // this.$store.dispatch(ESTIMATE_LIST_ACTION).then(() => {
    this.getDataInit(params);
    // });

    //this.excelKey = excelKey.ESTIMATE_PRUCHASE;
  },
  mounted() {},
  methods: {
    async getStatus() {
      const path = `${this.$apiPath.ESTIMATE_STATUS}/partner`;
      const res = await ApiService.shared.getData(path);
      const { code, data, text } = res;
      this.estimateStatusList = data;
    },
    getFilterbarParams(objData) {
      const { status, startDate, endDate, keywordSelectedId, keywordInputText } = objData;

      let params = {};

      if (status) params.status = status;

      if (startDate && endDate) {
        params["startDate"] = startDate;
        params["endDate"] = endDate;
      }

      if (keywordSelectedId && keywordInputText) params[keywordSelectedId] = keywordInputText;

      return params;
    },
  },
};
</script>
